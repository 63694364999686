import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axios";
import jwtDecode from "jwt-decode";
import { DecodedToken } from "../../../types/auth";

interface RegisterPayload {
  name: string;
  lastname: string;
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
  type: string;
  gender: string;
  city: string;
  profilePicUrl?: string;
  division: string;
  divisionType: string;
}

interface AuthenticationState {
  loading: boolean;
  error: string | null;
  success: boolean;
  user: null | User;
  divisionTypes: DivisionType[] | null;
  divisions: Division[] | null;
}
type DivisionType = {
  _id: string;
  name: string;
};

type Division = {
  _id: string;
  name: string;
  divisionType: string;
};
type User = {
  name: string;
  phone: number;
  email: string;
  password: string;
};

const initialState: AuthenticationState = {
  loading: false,
  error: null,
  success: false,
  user: null,
  divisionTypes: null,
  divisions: null,
};

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (payload: RegisterPayload) => {
    try {
      const response = await axiosInstance.post("/signup", payload);
      const data = response.data;

      return data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchDivisionTypes = createAsyncThunk(
  "auth/fetchDivisionTypes",
  async () => {
    try {
      const response = await axiosInstance.get(
        "/divisionTypes/all?active=true"
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const isValidToken = (token: string): boolean => {
  if (!token) {
    return false;
  }
  const decoded: DecodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      window.location.href = "/login";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })

      .addCase(registerUser.rejected, (state, action) => {
        const error: any = action.error;
        state.loading = false;
        state.error = error.message ?? "An error occurred";
        state.success = false;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
