import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { MetaType, OffreType } from "../../types/offre";

type Offre = {
  offre: OffreType;
  offres: OffreType[];
  meta: MetaType;
  loading: boolean;
  error: string;
  isApplied: boolean;
};

const initialState: Offre = {
  offre: {
    title: "",
    description: "",
    keywords: [],
    location: "",
    minPlaces: 0,
    maxPlaces: 0,
    startDate: 0,
    endDate: 0,
    divisionType: [],
    allowedDivisions: [],
    type: "",
  },
  isApplied: false,
  offres: [],
  meta: {
    hasMore: false,
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0,
  },
  loading: false,
  error: "",
};

type filter = {
  page?: number;
  divisions?: string[];
  divisionTypes?: string[];
};

export const getOffers: any = createAsyncThunk(
  "offers",
  async ({ page, divisionTypes, divisions }: filter) => {
    try {
      let currentPage = page || 1;
      let url = `/offer/all?perPage=6&page=${currentPage}&status=true`;

      if (divisionTypes?.length! > 0) {
        url += `&divisionType=${divisionTypes}`;
      }

      if (divisions?.length! > 0) {
        url += `&type=${divisions}`;
      }

      const response = await axiosInstance.get(
        `/offer/all?divisionType=${divisionTypes}`
      );

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export const getOffreById: any = createAsyncThunk(
  "type/OffreById",
  async (_id) => {
    try {
      const response = await axiosInstance.get(`/offer/${_id}`);
      return response?.data?.data;
    } catch (error: any) {
      return Promise.reject(error?.message);
    }
  }
);

export const offreSlice = createSlice({
  name: "Offre",
  initialState,
  reducers: {},
  extraReducers: {
    [getOffers.pending]: (state) => {
      state.loading = true;
    },

    [getOffers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offres = payload?.docs;
      state.meta = payload?.meta;
    },

    [getOffers.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },

    // ------------------------------------ //

    [getOffreById.pending]: (state) => {
      state.loading = true;
    },

    [getOffreById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.offre = payload.offer;
      state.isApplied = payload.isApplied;
    },

    [getOffreById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = offreSlice.actions;

export default offreSlice.reducer;
