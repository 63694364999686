import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axiosInstance from "../../../utils/axios";

export const resetpassword = createAsyncThunk(
  "/resetPassword",
  async ({
    password,
    passwordConfirm,
  }: {
    password: string;

    passwordConfirm: string;
  }) => {
    let data;
    try {
      const token = localStorage.getItem("token");
      const response = await axiosInstance.post(
        "/resetPassword",
        {
          password,
          passwordConfirm,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      data = await response.data;
      if (response.status === 200) {
        return data;
      }
    } catch (err: any) {
      return err.response.data;
    }
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetpassword.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });

    builder.addCase(resetpassword.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });

    builder.addCase(resetpassword.rejected, (state, action) => {
      const error: any = action.error;
      state.loading = false;
      state.error = error.message ?? "An error occurred";
      state.success = false;
    });
  },
});

export default resetPasswordSlice.reducer;
