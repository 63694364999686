import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axios";

interface ReSendCodePayload {
  email: string;
  type: string;
}

export const resendCode = createAsyncThunk(
  "resendCode",
  async (payload: ReSendCodePayload, { rejectWithValue, dispatch }) => {
    let data;

    try {
      const response = await axiosInstance.post(
        "/resendActivationCode",
        payload
      );

      return response;
    } catch (err: any) {
      if (err.response && err.response.data) {
      }
      throw err.response.data.message;
    }
  }
);

const resendCodeSlice = createSlice({
  name: "resendCodeSlice",
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {
    setResendError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resendCode.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });

    builder.addCase(resendCode.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });

    builder.addCase(resendCode.rejected, (state, action) => {
      const error: any = action.error;
      state.loading = false;
      state.error = error.message ?? "An error occurred";
      state.success = false;
    });
  },
});

export const { setResendError } = resendCodeSlice.actions;

export default resendCodeSlice.reducer;
