import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DivisionI } from "../../types/divisions";
import axiosInstance from "../../utils/axios";

type DivisionType = {
  divisions: DivisionI[];
  loading: boolean;
  error: string;
};

const initialState: DivisionType = {
  divisions: [],
  loading: false,
  error: "",
};

export const getDivisionTypes: any = createAsyncThunk(
  "type/divisionType",
  async () => {
    try {
      const response = await axiosInstance.get("/divisionTypes/all");
      return response?.data?.data?.docs;
    } catch (error) {
      return error;
    }
  }
);

export const getOneDivisionType: any = createAsyncThunk(
  "type/divisionType",
  async (id: string) => {
    try {
      const response = await axiosInstance.get(`/divisionTypes/${id}`);

      return response?.data?.data?.docs;
    } catch (error) {
      return error;
    }
  }
);

export const getDivisions: any = createAsyncThunk(
  "type/divisions",
  async () => {
    const response = await axiosInstance.get("/division/all");
    return response?.data?.data?.docs;
  }
);

export const divisionTypeSlice = createSlice({
  name: "DivisionType",
  initialState,
  reducers: {},
  extraReducers: {
    [getDivisionTypes.pending]: (state) => {
      state.loading = true;
    },

    [getDivisionTypes.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.divisions = payload;
    },

    [getDivisionTypes.rejected]: (state) => {
      state.loading = true;
    },
  },
});

export const {} = divisionTypeSlice.actions;

export default divisionTypeSlice.reducer;
