import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfile, isValidToken } from "../../data/slice/auth/authSlice";
import { useDispatch, useSelector } from "../../data/store";

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (token && isValidToken(token)) {
      if (
        user &&
        !user.isCompletedEducativeInformations &&
        pathname !== "/account-setup"
      ) {
        navigate("/account-setup");
      }
    } else navigate("/login");
  }, [user, token]);

  return <>{children}</>;
}
