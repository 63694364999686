import React from "react";
import "./loading.scss";
import { ClipLoader } from "react-spinners";

const Loading = ({ width, height }: { width?: string; height?: string }) => {
  return (
    <div className="loading" style={{ width: width, height: height }}>
      <ClipLoader color="#7225C1" size={100} />
    </div>
  );
};

export default Loading;
