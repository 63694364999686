import { Dropdown, MenuProps } from "antd";
import { Link } from "react-router-dom";
import icon_down from "../../assets/icons/icon_down.png";
import Logout from "../../assets/icons/logout.svg";
import edit from "../../assets/icons/pencil.svg";
import user1 from "../../assets/images/profile.webp";
import { logout } from "../../data/slice/auth/authSlice";
import { store, useSelector } from "../../data/store";
import { uploadURL } from "../../utils/axios";

const items: MenuProps["items"] = [
  {
    label: (
      <Link to="/edit-profile" className="menu-item">
        <img id="edit-img" src={edit} alt="icon pen" />
        <p>Edit Profile</p>
      </Link>
    ),
    key: "0",
  },
  {
    label: (
      <Link
        to="/"
        className="menu-item"
        onClick={() => store.dispatch(logout())}
      >
        <img id="logout-img" src={Logout} alt="logout icon" />
        <p>Logout</p>
      </Link>
    ),
    key: "1",
  },
];

const User = () => {
  const { user } = useSelector((state: any) => state.auth);
  const profilePicUrl = user?.profilePicUrl;

  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <div className="user_blog">
        <div className="profile-pic">
          <img
            src={profilePicUrl ? `${uploadURL}/${user?.profilePicUrl}` : user1}
            alt="Profile pic"
            id="user-header"
          />
        </div>

        <img src={icon_down} alt="icon down" className={"icon_down"} />
      </div>
    </Dropdown>
  );
};

export default User;
