import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import close from "../../assets/icons/close.svg";
import left_icon from "../../assets/icons/left_icon.svg";
import logOut from "../../assets/icons/logout.svg";
import right_icon from "../../assets/icons/right_icon.svg";
import logo2 from "../../assets/icons/softylinesLogo.webp";
import Nav from "../../components/Nav";
import { navs } from "../../data/constants";
import { logout } from "../../data/slice/auth/authSlice";
import {
  toggleSideBar,
  toggleSideBarMobile,
  toggleSideBarWeb,
} from "../../data/slice/sidebarSlice";
import useWindowSize from "../../hooks/useWindowSize";
import "./sideBar.scss";

const SideBar = () => {
  const { isSmall, isMobile } = useSelector((state: any) => state.SideBar);
  const { user } = useSelector((state: any) => state.auth);

  const dispatch = useDispatch();

  const windowSize = useWindowSize();
  const width = windowSize?.width;

  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      width <= 850
        ? dispatch(toggleSideBarMobile())
        : dispatch(toggleSideBarWeb());
    }
  }, [dispatch, width]);

  return (
    <div
      className={`sidebar  ${isSmall && !isMobile ? "small_width" : ""} ${
        isMobile ? "sideBar_hidden" : ""
      } `}
    >
      {width <= 850 && !isSmall && (
        <div
          className="overlay"
          onClick={() => dispatch(toggleSideBarMobile())}
        ></div>
      )}

      <div className="sidebar_top">
        <img src={logo2} alt="logo" className="logo2" />
        <div className="user-sidebar">
          {!isSmall && <p> Welcome </p>}
          {!isSmall && <p>{user?.name}</p>}
        </div>

        <div
          className="icon_container"
          onClick={() => dispatch(toggleSideBar())}
        >
          {isSmall ? (
            <img src={right_icon} alt="icon right" />
          ) : (
            <img src={width > 850 ? left_icon : close} alt="icon left" />
          )}
        </div>
      </div>

      <div className="navs">
        {navs.map((nav, index) => {
          return (
            <NavLink
              to={`${nav.link}`}
              key={index}
              onClick={() => {
                width <= 850 && dispatch(toggleSideBar());
              }}
            >
              <Nav title={nav?.title} icon={nav?.icon} />
            </NavLink>
          );
        })}
      </div>

      <div className="sidebar_bottom" onClick={() => dispatch(logout())}>
        <img src={logOut} alt="logout icon" />
        {!isSmall && <p>Logout</p>}
      </div>
    </div>
  );
};

export default SideBar;
