import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IMeetingAccountGeneral } from "../../../types/meeting";
import axiosInstance from "../../../utils/axios";

interface MeetingType {
  meeting: IMeetingAccountGeneral;
  meetingList: IMeetingAccountGeneral[];
  loading: boolean;
  isFetchStudentsLoading: "idle" | "loading" | "success" | "failed";

  error: string;
  meta: object | null;
}

const initialState: MeetingType = {
  meeting: {
    date: "",
    _id: "",
    type: "",
  },
  isFetchStudentsLoading: "idle",
  meta: null,
  meetingList: [],
  loading: false,
  error: "",
};

export const fetchMeetings: any = createAsyncThunk(
  "meetings/fetchMeetings",

  async (payload: { params?: any }) => {
    try {
      const response = await axiosInstance.get("/meeting/all", {
        params: payload?.params,
      });
      return response.data.data;
    } catch (error) {
      return error;
    }
  }
);

export const getMeetingById: any = createAsyncThunk(
  "meeting/getmeetingById",
  // eslint-disable-next-line consistent-return
  async (_id) => {
    try {
      const response = await axiosInstance.get(`/meeting/${_id}`);

      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const updateMeeting: any = createAsyncThunk(
  "meeting/updateMeeting",
  // eslint-disable-next-line consistent-return
  async (data: IMeetingAccountGeneral) => {
    const { id, ...requestData } = data;

    try {
      const response = await axiosInstance.put(`/meeting/${id}`, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data.docs;
    } catch (error) {
      return error;
    }
  }
);

export const meetingListSlice: any = createSlice({
  name: "Meetings",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMeetings.pending]: (state) => {
      state.loading = true;
      state.isFetchStudentsLoading = "loading";
    },

    [fetchMeetings.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isFetchStudentsLoading = "success";
      state.meetingList = payload.docs;
      state.meta = payload.meta;
    },

    [fetchMeetings.rejected]: (state, { error }) => {
      state.loading = false;
      state.isFetchStudentsLoading = "failed";
      state.error = error;
    },
    // ---------------- //

    [getMeetingById.pending]: (state) => {
      state.loading = true;
    },

    [getMeetingById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.meeting = payload.data;
    },

    [getMeetingById.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
    [updateMeeting.pending]: (state) => {
      state.loading = true;
    },

    [updateMeeting.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.meeting = payload;
    },
    [updateMeeting.rejected]: (state, { error }) => {
      state.loading = false;
      state.error = error;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = meetingListSlice.actions;

export default meetingListSlice.reducer;
