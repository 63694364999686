import axios from "axios";

const baseURL = process.env.REACT_APP_API_ENDPOINT;
export const uploadURL = process.env.REACT_APP_API_UPLOADS_URL;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

export default axiosInstance;
