import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

type citiesType = {
  cities: string[];
  loading: boolean;
  error: string;
};

const initialState: citiesType = {
  cities: [],
  loading: false,
  error: "",
};

export const getCities: any = createAsyncThunk("type/citiesData", async () => {
  const response = await axiosInstance.get("/cities");
  return response?.data?.data;
});

export const citiesSlice = createSlice({
  name: "PageName",
  initialState,
  reducers: {},
  extraReducers: {
    [getCities.pending]: (state) => {
      state.loading = true;
    },

    [getCities.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.cities = payload;
    },

    [getCities.rejected]: (state, { error }) => {
      state.error = error.message;
    },
  },
});

export const {} = citiesSlice.actions;

export default citiesSlice.reducer;
