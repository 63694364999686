import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axios";

interface activeAcountPayload {
  code: number;
  email: string;
  type: string;
}

export const activeAccount = createAsyncThunk(
  "activeAccount",
  async (payload: activeAcountPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/validateAccount", payload);

      if (response) {
        localStorage.setItem("token", response.data.data.tokens.accessToken);
        return response;
      }
    } catch (err: any) {
      throw err.response.data.message;
    }
  }
);

const activeAccountSlice = createSlice({
  name: "activeAccountSlice",
  initialState: {
    loading: false,
    error: null,
    success: false,
    timer: 0,
    token: null,
  },
  reducers: {
    setResendError: (state, action) => {
      state.error = action.payload;
    },
    setTimer: (state, action) => {
      state.timer = action.payload;
    },
    setAccessToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(activeAccount.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });

    builder.addCase(activeAccount.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });

    builder.addCase(activeAccount.rejected, (state, action) => {
      const error: any = action.error;
      state.loading = false;
      state.error = error.message;
      state.success = false;
    });
  },
});

export const { setResendError, setTimer, setAccessToken } =
  activeAccountSlice.actions;
export default activeAccountSlice.reducer;
