import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axios";
import jwtDecode from "jwt-decode";
import { DecodedToken } from "../../../types/auth";
import { message } from "antd";

type accoutSetupProps = {
  name: string;
  lastname: string;
  phone: string;
  divisionType: string;
  division: string;
  profilePicUrl?: any;
  gender: string;
  city: string;
  email: string;
  identityNumber: string;
  inscriptionNumber: string;
};

interface AuthenticationState {
  loading: boolean;
  error: string | null;
  success: boolean;
  user: any;
  isAuth: boolean;
}

const initialState: AuthenticationState = {
  loading: false,
  error: null,
  success: false,
  user: null,
  isAuth: false,
};

export const isValidToken = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const decoded: DecodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const setSession = (token: string | null) => {
  if (token && isValidToken(token)) {
    localStorage.setItem("token", token);
  } else {
    localStorage.removeItem("token");
    delete axiosInstance.defaults.headers.common.Authorization;
    // window.location.href = "/login";
  }
};

export const getProfile = createAsyncThunk("auth/getProfile", async () => {
  let data;

  try {
    const response = await axiosInstance.get("/profile/my");

    data = response.data;
    if (response.status === 200) {
      return data?.data;
    }
  } catch (error) {
    localStorage.removeItem("token");
    window.location.href = "/login";
  }
});

export const checkIsUserExist = createAsyncThunk(
  "auth/isUserExist",
  async ({ email }: { email: string }) => {
    try {
      const response = await axiosInstance.post(`/checkUserExist`, { email });

      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      return Promise.reject(error.message);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signup",
  async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await axiosInstance.post(`/signup`, {
        email,
        password,
        type: "STUDENT",
      });

      if (response.status === 200) {
        return response?.data;
      }
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }
);

export const logIn = createAsyncThunk(
  `auth/login`,
  async ({ email, password }: { email: string; password: string }) => {
    try {
      const response = await axiosInstance.post("/login", {
        email,
        password,
        type: "STUDENT",
      });

      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }
);

export const setAccountSetup = createAsyncThunk(
  "auth/account-setup",
  async ({
    name,
    lastname,
    phone,
    divisionType,
    division,
    gender,
    city,
    profilePicUrl,
    email,
    identityNumber,
    inscriptionNumber,
  }: accoutSetupProps) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("lastname", lastname);
    phone && formData.append("phone", phone);
    formData.append("divisionType", divisionType);
    division && formData.append("division", division);
    formData.append("gender", gender);
    formData.append("city", city);
    formData.append("identityNumber", identityNumber);
    formData.append("inscriptionNumber", inscriptionNumber);
    //  formData.append("email", email);
    profilePicUrl && formData.append("profilePicUrl", profilePicUrl);

    try {
      const response = await axiosInstance.put(
        `/profile/complete-basic`,
        formData
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }
);

export const editAccountSetup = createAsyncThunk(
  "auth/account-setup",
  async ({
    name,
    lastname,
    phone,
    divisionType,
    division,
    gender,
    city,
    profilePicUrl,
    email,
    identityNumber,
    inscriptionNumber,
  }: accoutSetupProps) => {
    try {
      const formData = new FormData();
      name && formData.append("name", name);
      lastname && formData.append("lastname", lastname);
      phone && formData.append("phone", phone);
      divisionType && formData.append("divisionType", divisionType);
      division && formData.append("division", division);
      gender && formData.append("gender", gender);
      city && formData.append("city", city);
      email && formData.append("email", email);
      profilePicUrl && formData.append("profilePicUrl", profilePicUrl);
      identityNumber && formData.append("identityNumber", identityNumber);
      inscriptionNumber &&
        formData.append("inscriptionNumber", inscriptionNumber);

      const response = await axiosInstance.put(`/profile`, formData);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      return Promise.reject(error?.response?.data);
    }
  }
);

export const setGradesAndDiploma = createAsyncThunk(
  "auth/grades-and-diploma",
  async (data: any) => {
    const { studies_informations, specific_informations, common_informations } =
      data;

    const formData = new FormData();

    formData.append(
      "studies_informations[globalMark]",
      studies_informations.globalMark
    );

    studies_informations.lastDegreeDiplomaPicUrl &&
      formData.append(
        "studies_informations.lastDegreeDiplomaPicUrl[]",
        studies_informations.lastDegreeDiplomaPicUrl
      );

    formData.append(
      "studies_informations.gradesPicUrl[]",
      studies_informations.gradesPicUrl
    );

    studies_informations.subjectsMarks.map((mark: any, index: number) => {
      const key = Object.keys(mark)[0];
      const value: any = Object.values(mark)[0];
      formData.append(
        `studies_informations[subjectsMarks][${index}][subject]`,
        key
      );
      formData.append(
        `studies_informations[subjectsMarks][${index}][mark]`,
        value
      );
    });

    specific_informations.socialUrl &&
      formData.append(
        "specific_informations[socialUrl]",
        specific_informations.socialUrl
      );

    common_informations.motivationalLetterPicUrl &&
      formData.append(
        "common_informations.motivationalLetterPicUrl",
        common_informations.motivationalLetterPicUrl
      );

    formData.append(
      "common_informations.resumePicUrl",
      common_informations.resumePicUrl
    );

    try {
      const response = await axiosInstance.put(
        `/profile/complete-education`,
        formData
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error: any) {
      return Promise.reject(error.response.data);
    }
  }
);

//============

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.clear();
      state.isAuth = false;
      state.user = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "An error occurred";
        state.success = false;
      })
      .addCase(logIn.pending, (state) => {
        state.error = null;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.isAuth = true;
      })
      .addCase(logIn.rejected, (state, action) => {
        state.error = "error";
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
