import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationType, MetaType } from "../../../types/offre";
import axiosInstance from "../../../utils/axios";

interface AuthenticationState {
  loading: boolean;
  error: string | null;
  success: boolean;
  applications: ApplicationType[];
  meta: MetaType;
  application: ApplicationType;
}

const initialState: AuthenticationState = {
  loading: true,
  error: null,
  success: false,
  applications: [],
  meta: {
    hasMore: false,
    hasNextPage: false,
    hasPrevPage: false,
    limit: 0,
    nextPage: 0,
    page: 0,
    pagingCounter: 0,
    totalDocs: 0,
    totalPages: 0,
  },
  application: {
    _id: "",
    user_id: "",
    offer_id: "",
    studies_informations: [],
    common_informations: "",
    specific_informations: 0,
    createdAt: "",
    acceptationProcess: [],
    status: "",
  },
};

// new application
export const handleApply = createAsyncThunk(
  "offer/apply",
  async (offerId: string) => {
    try {
      const response = await axiosInstance.post(`/offer/${offerId}/apply`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }
);

export const apply = createAsyncThunk(
  "/apply",
  async ({
    offerId,
    applicationData,
  }: {
    offerId: string;
    applicationData: FormData;
  }) => {
    try {
      const response = await axiosInstance.post(
        `/offer/${offerId}/apply`,
        applicationData
      );
      const data = response.data;
      return data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const editApplication = createAsyncThunk(
  "/editApply",
  async ({ id, values }: { id: string; values: any }) => {
    try {
      const response = await axiosInstance.put(
        `/application/my/${id}`,
        values,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const data = response.data;
      return data;
    } catch (error) {
      return error;
    }
  }
);

export const getMyApplications = createAsyncThunk(
  "/application/my",
  async (page: number) => {
    try {
      const response = await axiosInstance.get(
        `/application/my?perPage=6&page=${page}`
      );
      const data = response.data;
      return data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const getApplicationById = createAsyncThunk(
  "/application/id",
  async (id: string) => {
    try {
      const response = await axiosInstance.get(`/application/${id}`);
      const data = response.data;
      return data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const deleteMyApplicationById = createAsyncThunk(
  "/application/id/delete",
  async (id: string) => {
    try {
      const response = await axiosInstance.delete(`/application/${id}`);
      const data = response.data;
      return data;
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }
);

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(apply.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(apply.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(apply.rejected, (state, action) => {
        const error: any = action.error;
        state.loading = false;
        state.error = error.message ?? "An error occurred";
        state.success = false;
      })
      .addCase(editApplication.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(editApplication.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(editApplication.rejected, (state, action) => {
        const error: any = action.error;
        state.loading = false;
        state.error = error.message ?? "An error occurred";
        state.success = false;
      })
      .addCase(getMyApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getMyApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.applications = action?.payload?.data?.docs;
        state.meta = action?.payload?.data?.meta;
      })
      .addCase(getMyApplications.rejected, (state, action) => {
        const error: any = action.error;
        state.loading = false;
        state.error = error.message ?? "An error occurred";
        state.success = false;
      })
      .addCase(getApplicationById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getApplicationById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.application = action?.payload?.data;
      })
      .addCase(getApplicationById.rejected, (state, action) => {
        const error: any = action.error;
        state.loading = false;
        state.error = error.message ?? "An error occurred";
        state.success = false;
      })
      .addCase(deleteMyApplicationById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(deleteMyApplicationById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
      })
      .addCase(deleteMyApplicationById.rejected, (state, action) => {
        const error: any = action.error;
        state.loading = false;
        state.error = error.message ?? "An error occurred";
        state.success = false;
      });
  },
});

export default applicationSlice.reducer;
