import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import menu from "../../assets/icons/menu.png";
import { getProfile } from "../../data/slice/auth/authSlice";
import { toggleSideBar } from "../../data/slice/sidebarSlice";
import { dispatch } from "../../data/store";
import "./header.scss";
import NotificationDropDown from "./NotificationDropDown";
import User from "./User";

const Header = () => {
  const { pathname } = useLocation();
  const { isSmall } = useSelector((state: any) => state.SideBar);

  const currentURL = window.location.href;
  const [currentPage, setCurrentPage] = useState("");

  useEffect(() => {
    try {
      dispatch(getProfile());
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  }, []);

  useEffect(() => {
    if (currentURL.includes("edit-profile")) setCurrentPage("Edit Profile");

    if (currentURL.includes("offer-details")) {
      setCurrentPage("Offer Details");
    }

    if (currentURL.includes("my-applications")) {
      setCurrentPage("My Application");
    }

    if (currentURL.includes("apply")) {
      setCurrentPage("Application Offer");
    }

    if (pathname === "/") setCurrentPage("Offers");
  }, [currentURL]);

  return (
    <header className={`header ${isSmall ? "small" : ""}`}>
      <div className="header_left">
        <img
          src={menu}
          alt="menu icon"
          className="iconMenu"
          onClick={() => dispatch(toggleSideBar())}
        />
        <p>{currentPage}</p>
      </div>

      <div className="header_right">
        <NotificationDropDown />

        <User />
      </div>
    </header>
  );
};

export default Header;
