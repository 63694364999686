import { Fragment, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./components/Loading";
import AuthGuard from "./layouts/guards/AuthGuard";
import MainLayout from "./layouts/MainLayout";
import { IRouteItem } from "./types/routes";
import { pageLoader } from "./utils/lazyImport";
import GuestGuard from "./layouts/guards/GuestGuard";
export const RenderRoutes = (routes: IRouteItem[] = []) => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Guard>
                  <Layout>
                    <Component />
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

const routes: IRouteItem[] = [
  {
    exact: true,
    path: "/login",
    component: lazy(pageLoader(() => import("./pages/auth/Auth"))),
    guard: GuestGuard,
  },
  {
    exact: true,
    path: "/active-account",
    component: lazy(
      pageLoader(() => import("./pages/activeAcoount/activeAccount"))
    ),
  },
  {
    exact: true,
    path: "/resetpassword",
    component: lazy(
      pageLoader(() => import("./pages/resetPassword/ResetPassword"))
    ),
  },
  {
    exact: true,
    path: "/sendCode",
    component: lazy(pageLoader(() => import("./pages/Code/code"))),
  },
  {
    exact: true,
    path: "/forgetpassword",
    component: lazy(
      pageLoader(() => import("./pages/forgetPassword/Forgetpassword"))
    ),
  },
  {
    exact: true,
    path: "/account-setup",
    component: lazy(pageLoader(() => import("./pages/AccountSetup"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "/",
    component: lazy(pageLoader(() => import("./pages/Offres"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "offer-details/:offre_id",
    component: lazy(pageLoader(() => import("./pages/OffreDetails"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "/edit-profile",
    component: lazy(pageLoader(() => import("./pages/EditProfile"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "/offer-details/:offre_id/apply",
    component: lazy(pageLoader(() => import("./pages/ApplicationForm"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "/offer-details/:offre_id/edit",
    component: lazy(pageLoader(() => import("./pages/EditApplication"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "my-applications",
    component: lazy(pageLoader(() => import("./pages/MyApplication"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "/my-applications/:application_id",
    component: lazy(
      pageLoader(() => import("./pages/Applications/ApplicationDetails"))
    ),
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    exact: true,
    path: "*",
    component: lazy(pageLoader(() => import("./pages/Error"))),
    guard: AuthGuard,
    layout: MainLayout,
  },
];
export default routes;
