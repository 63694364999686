import { createSlice } from "@reduxjs/toolkit";
import useWindowSize from "../../hooks/useWindowSize";

type Sidebar = {
  isSmall: boolean;
  isMobile: boolean;
};

const initialState: Sidebar = {
  isSmall: false,
  isMobile: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSideBar: (state) => {
      state.isSmall = !state.isSmall;
      state.isMobile = false;
    },

    toggleSideBarMobile: (state) => {
      state.isMobile = true;
    },

    toggleSideBarWeb: (state) => {
      state.isMobile = false;
    },
  },
});

export const { toggleSideBar, toggleSideBarMobile, toggleSideBarWeb } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
