import { Dropdown, MenuProps } from "antd";
import bell from "../../assets/icons/bell.png";
import Notification from "../../components/Notification";

const items: MenuProps["items"] = [
  {
    label: <Notification />,
    key: "1",
  },
];

const NotificationDropDown = () => {
  return (
    <Dropdown menu={{ items }} trigger={["click"]}>
      <img src={bell} alt="notification icon" className="notification_icon" />
    </Dropdown>
  );
};

export default NotificationDropDown;
