import ReactDOM from "react-dom";
import { store } from "./data/store";
import { Provider } from "react-redux";
import React, { useEffect, useState } from "react";
import App from "./App";
import "./App.scss";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer, ToastPosition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfigProvider } from "antd";

const Root = () => {
  const [toastPosition, setToastPosition] = useState<ToastPosition | undefined>(
    window.innerWidth <= 500 ? "bottom-right" : "top-right"
  );

  useEffect(() => {
    const handleResize = () => {
      setToastPosition(window.innerWidth <= 500 ? "bottom-right" : "top-right");
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: "#590696",
              borderRadius: 2,
              colorBgContainer: "#fff",
            },
          }}
        >
          <App />
        </ConfigProvider>
      </Provider>
      <ToastContainer
        position={toastPosition}
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="tostify"
      />
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

reportWebVitals();
