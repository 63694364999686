import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isValidToken } from "../../data/slice/auth/authSlice";
import { useSelector } from "../../data/store";

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const navigate = useNavigate();

  const { isAuth } = useSelector((state) => state.auth);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token && isValidToken(token) && isAuth) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [token, isAuth]);

  return <>{children}</>;
}
