import { createSlice } from "@reduxjs/toolkit";

type Notification = {
  isNotifOpen: boolean;
};

const initialState: Notification = {
  isNotifOpen: false,
};

export const notificationModal = createSlice({
  name: "notification",
  initialState,
  reducers: {
    toggleNotif: (state) => {
      state.isNotifOpen = !state.isNotifOpen;
    },
    CloseNotif: (state) => {
      state.isNotifOpen = false;
    },
  },
});

export const { toggleNotif, CloseNotif } = notificationModal.actions;

export default notificationModal.reducer;
