import { configureStore } from "@reduxjs/toolkit";
import SideBarReducer from "../slice/sidebarSlice";
import ModalReducer from "../slice/modalSlice";
import authSlice from "../slice/auth/authSlice";
import registerSlice from "../slice/auth/registerSlice";
import resendCodeReducer from "../slice/auth/resendSlice";
import activeAccountSlice from "../slice/auth/activateSlice";
import OffreReducer from "../slice/offreSlice";
import ApplicatonReducer from "../slice/application/applicationSlice";
import resetPasswordSlice from "../slice/auth/resetSlice";
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import divisionTypeReducer from "../slice/divisionSlice";
import CitiesReducer from "../slice/citiesSlice";
import NotificationReducer from "../slice/notification";
import MettingReducer from '../slice/meeting/meetingSlice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    SideBar: SideBarReducer,
    Modal: ModalReducer,
    Offre: OffreReducer,
    register: registerSlice,
    resendCodeSlice: resendCodeReducer,
    activeAccountSlice: activeAccountSlice,
    Divisions: divisionTypeReducer,
    Cities: CitiesReducer,
    Application: ApplicatonReducer,
    resetPassword: resetPasswordSlice,
    Notification: NotificationReducer,
    meetings: MettingReducer,

  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

const useDispatch = () => useAppDispatch<AppDispatch>();

export { store, dispatch, useSelector, useDispatch };
