import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./nav.scss";

type navProps = {
  icon: string;
  title?: string;
};

const Nav = ({ icon, title }: navProps) => {
  const { isSmall } = useSelector((state: any) => state.SideBar);

  return (
    <div className={`nav  ${isSmall ? "nav_small" : ""}`}>
      <img src={icon} alt="icon" />

      {!isSmall && <p>{title}</p>}
    </div>
  );
};

export default Nav;
