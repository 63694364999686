import React, { useEffect, useRef } from "react";
import "./notification.scss";

const Notification = () => {
  return (
    <div className="notification">
      <p>There is no notification yet!</p>
    </div>
  );
};

export default Notification;
